<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4" cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          Sebis interaktives Junggesellen Quiz
        </h1>
      </v-col>

      <v-col class="mb-5" cols="12" v-if="!startedQuiz">
        <v-img
          :src="require('../assets/banner.jpg')"
          class="my-3"
          contain
          height="400"
        />
        <h2 class="headline font-weight-bold mb-3">
          Nachdem Sebi den Ehequiz seiner holden Maid Marion wahrscheinlich mit
          "JA" beantworten wird, wollen wir ihn noch ein letztes Mal mit den
          wirklichen Fragen des Lebens konfrontieren.
        </h2>
        <v-btn color="error" @click="startedQuiz = true"
          >Beginne deinen Untergang!</v-btn
        >
      </v-col>
      <v-col v-else-if="currentQuestion < questions.length" cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" v-if="questions[currentQuestion].questionImage">
                <v-img
                  :src="
                    require('../assets/' +
                      questions[currentQuestion].questionImage)
                  "
                  class="my-3"
                  contain
                  height="400"
                />
              </v-col>
              <v-col cols="12" v-if="questions[currentQuestion].questionVideo">
                <video
                  height="400"
                  controls
                  :src="
                    require('../assets/' +
                      questions[currentQuestion].questionVideo)
                  "
                  type="video/mp4"
                ></video>
              </v-col>

              <v-col cols="12" class="headline font-weight-bold">
                {{ questions[currentQuestion].questionText }}
              </v-col>
              <v-col
                v-for="(answer, index) in questions[currentQuestion]
                  .answerOptions"
                :key="'answer-' + index"
                cols="12"
                class="py-1"
              >
                <v-btn
                  min-width="200"
                  color="success"
                  @click="answer.isCorrect ? ++currentQuestion : (zonk = true)"
                  >{{ answer.answerText }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-5" cols="12" v-else>
        <v-img
          :src="require('../assets/bernerwuerstel.jpg')"
          class="my-3"
          contain
          height="400"
        />
        <h2 class="headline font-weight-bold mb-3">
          Hier deine Belohnung für die ganzen Mühen! Gratulation, du hast es
          geschafft!
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog persistent v-model="zonk" max-width="600px">
        <v-card>
          <v-card-text>
            <v-container>
              <v-row class="text-center">
                <v-col cols="12">
                  <v-img
                    :src="require('../assets/zonk.gif')"
                    class="my-3"
                    contain
                    height="400"
                  />
                </v-col>
                <v-col cols="12">
                  <h2 class="headline font-weight-bold mb-3">
                    „Hab' ich saufi gehört?“<br />
                    „Ja, du hast saufi gehört“
                  </h2> </v-col
                ><v-col cols="12">
                  <v-btn color="error" @click="mixAnswers">Nochmal</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    startedQuiz: 0,
    currentQuestion: 0,
    zonk: false,
    questions: [
      {
        questionText: "Wo fand Sebi neue Freunde?",
        questionImage: "muellnerstubn.jpg",
        answerOptions: [
          { answerText: "Flip", isCorrect: false },
          { answerText: "Müllnerstubn", isCorrect: true },
          { answerText: "Müllnerbräu", isCorrect: false },
          { answerText: "Sub", isCorrect: false },
        ],
      },
      {
        questionText: "Wieviel Personen schlafen hier?",
        questionVideo: "video.mp4",
        answerOptions: [
          { answerText: "3", isCorrect: false },
          { answerText: "4", isCorrect: true },
          { answerText: "4,5", isCorrect: false },
          { answerText: "5", isCorrect: false },
        ],
      },
      {
        shuffle: true,
        questionText: "Welche Augenfarbe hat Marion?",
        questionImage: "marion.jpg",
        answerOptions: [
          { answerText: "helles Eisblau", isCorrect: false },
          { answerText: "Hellgrün", isCorrect: false },
          { answerText: "Blaugrau", isCorrect: true },
          { answerText: "Saphirblau", isCorrect: false },
          { answerText: "Himmelblau", isCorrect: false },
          { answerText: "Grüngrau", isCorrect: false },
        ],
      },
      {
        shuffle: true,
        questionText:
          "Wann hat Marion den Lehrgang für Dirigenten von Blasorchestern besucht?",
        questionImage: "lehrgang.jpg",
        answerOptions: [
          { answerText: "vom 24.08.2016 bis 03.04.2018", isCorrect: false },
          { answerText: "vom 31.08.2016 bis 22.03.2018", isCorrect: false },
          { answerText: "vom 28.08.2016 bis 31.03.2018", isCorrect: true },
          { answerText: "vom 26.08.2016 bis 27.03.2018", isCorrect: false },
        ],
      },
      {
        questionText:
          "Wann feiert die älterste Brauerei der Welt ihr Millenium?",
        questionImage: "brauerei.jpg",
        answerOptions: [
          { answerText: "2039", isCorrect: false },
          { answerText: "2041", isCorrect: false },
          { answerText: "2042", isCorrect: false },
          { answerText: "2040", isCorrect: true },
        ],
      },
      {
        shuffle: true,
        questionText:
          "Führe die Reihe fort: 3.14159265358979323846264338327950288419716939937510582097494459230781640628620899862803482",
        questionImage: "pi.jpg",
        answerOptions: [
          { answerText: "53421170679", isCorrect: true },
          { answerText: "53421170669", isCorrect: false },
          { answerText: "53421170779", isCorrect: false },
          { answerText: "53421170677", isCorrect: false },
        ],
      },
      {
        questionText: "Was siehst du auf diesem Bild?",
        questionImage: "taeuschung.jpg",
        answerOptions: [
          { answerText: "Eng umschlungenes nacktes Pärchen", isCorrect: false },
          { answerText: "Sebi und Marion beim Zähneputzen", isCorrect: false },
          {
            answerText: "Nonnen beim Durchführen von Liegestützen",
            isCorrect: false,
          },
          { answerText: "Etwas anderes", isCorrect: true },
        ],
      },
      {
        questionText: "Wann heiratet unser Sebi?",
        questionImage: "heirat.jpg",
        answerOptions: [
          { answerText: "11.Juni 2022 um 09:30 Uhr", isCorrect: false },
          { answerText: "11.Juni 2022 um 10:00 Uhr", isCorrect: true },
          { answerText: "11.Juni 2022 um 10:30 Uhr", isCorrect: false },
          { answerText: "11.Juni 2022 um 11:00 Uhr", isCorrect: false },
        ],
      },
    ],
  }),

  methods: {
    mixAnswers() {
      const shuffle = (a) => {
        for (let i = a.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
      };
      if (this.questions[this.currentQuestion].shuffle) {
        shuffle(this.questions[this.currentQuestion].answerOptions);
      }
      this.zonk = false;
    },
  },
};
</script>
